.container {
    margin: 0 25% 0 25%;
}

@media (min-width: 320px) and (max-width: 480px) {
    /* Mobile (Portrait) */
    .container {
        margin: 0 5% 0 5%;
    }
  }
  
  @media (min-width: 481px) and (max-width: 1024px) {
    /* Mobile (Landscape) & Tablets */
    /* Tablets & Small Desktops */
    .container {
        margin: 0 10% 0 10%;
    }
  }
  
  @media (min-width: 1025px) and (max-width: 1200px) {
    /* Large Desktops */
    .container {
        margin: 0 15% 0 15%;
    }
  }

.header {
    padding-top: 32px;
    text-align: center;
    cursor: pointer;
}

.image {
    display: block;
}

@media (min-width:320px)  {
    .container {
        margin: 0 10% 0 10%;
    }

    .image {
        margin: 1em auto 1em auto;
        max-width: 100%;
    }
}

@media (min-width:961px) {
    .container {
        margin: 0 20% 0 20%;
    }

    .image {
        margin: 1.5em auto 1.5em auto;
        max-width: 90%;
    }
}

  
@media (min-width:1281px) {
    .container {
        margin: 0 30% 0 30%;
    }

    .image {
        display: block;
        margin: 32px auto 32px auto;
        max-width: 80%;
    }
}