:root {
  --light-font: 'NotoSans-Light';
  --regular-font: 'NotoSans-Regular';
  --medium-font: 'NotoSans-Medium';
  --semibold-font: 'NotoSans-SemiBold';
  --black: #1a1a1d;
  --charcoal: #222225;
  --minorwhite: #f6f6f6c1;
  --mainwhite : #fefefe;
  --large-font-size: 2.5vw;
  --regular-font-size: 1.5vw;
  --medium-font-size: 1.25vw;
  --small-font-size: 1vw;
}

@media (min-width: 320px) and (max-width: 480px) {
  /* Mobile (Portrait) */
  :root {
    --large-font-size: clamp(28px, 7vw, 30px);
    --medium-font-size: clamp(18px, 6vw, 20px);
    --regular-font-size: clamp(14px, 4.5vw, 16px);
    --small-font-size: clamp(12px, 4vw, 13px);
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  /* Mobile (Landscape) & Tablets */
  :root {
    --large-font-size: clamp(28px, 5vw, 30px);
    --medium-font-size: clamp(20px, 2vw, 22px);
    --regular-font-size: clamp(16px, 3.5vw, 18px);
    --small-font-size: clamp(13px, 3vw, 15px);
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Tablets & Small Desktops */
  :root {
    --large-font-size: clamp(30px, 3.75vw, 32px);
    --medium-font-size: clamp(20px, 2vw, 22px);
    --regular-font-size: clamp(16px, 2vw, 18px);
    --small-font-size: clamp(13px, 1.75vw, 15px);
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  /* Large Desktops */
  :root {
    --large-font-size: clamp(30px, 3vw, 32px);
    --medium-font-size: clamp(20px, 2vw, 22px);
    --regular-font-size: clamp(16px, 1.75vw, 18px);
    --small-font-size: clamp(14px, 1.4vw, 16px);
  }
}

@media (min-width: 1201px) {
  /* Extra Large Screens */
  :root {
    --large-font-size: clamp(32px, 2.5vw, 34px);
    --medium-font-size: clamp(20px, 2vw, 22px);
    --regular-font-size: clamp(14px, 1.5vw, 16px);
    --small-font-size: clamp(14px, 1.25vw, 16px);
  }
}

body,
html,
#app {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  caret-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--regular-font);
  font-size: var(--regular-font-size);
}

@font-face {
  font-family: 'NotoSans-Light';
  src: local('NotoSans-Light'), url('./Fonts/NotoSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-Regular';
  src: local('NotoSans-Regular'), url('./Fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-Medium';
  src: local('NotoSans-Medium'), url('./Fonts/NotoSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSans-SemiBold';
  src: local('NotoSans-SemiBold'), url('./Fonts/NotoSans-SemiBold.ttf') format('truetype');
}