.container {
    display: grid;
    margin: 0 30% 0 30%;
    align-items: center;
}

.headshot {
    object-fit: cover;
    margin: 16px auto 10px auto;
    width: 100%;
    max-width: 40%;
    height: auto;
    border-radius: 100%;
}


@media (min-width: 320px) and (max-width: 480px) {
    /* Mobile (Portrait) */
    .container {
        margin: 0 10% 0 10%;
    }

    .headshot {
        max-width: 60%;
    }
}

@media (min-width: 481px) and (max-width: 720px) {
    /* Mobile (Landscape) & Tablets */
    /* Tablets & Small Desktops */
    .container {
        margin: 0 5% 0 5%;
    }

    .headshot {
        max-width: 50%;
    }
}
  
@media (min-width: 721px) and (max-width: 1024px) {
    /* Mobile (Landscape) & Tablets */
    /* Tablets & Small Desktops */
    .container {
        margin: 0 5% 0 5%;
    }

    .headshot {
        max-width: 40%;
    }
}
  
@media (min-width: 1025px) and (max-width: 1200px) {
    /* Large Desktops */
    .container {
        margin: 0 10% 0 10%;
    }

    .headshot {
        max-width: 60%;
    }
}

@media (min-width: 1201px) and (max-width: 1600px){
    /* Large Desktops */
    .container {
        margin: 0 15% 0 15%;
    }

    .headshot {
        max-width: 60%;
    }
}

@media (min-width: 1601px) and (max-width: 1920px){
    /* Large Desktops */
    .container {
        margin: 0 10% 0 10%;
    }

    .headshot {
        max-width: 40%;
    }
}

@media (min-width: 1921px){
    /* Large Desktops */
    .container {
        margin: 0 20% 0 20%;
    }

    .headshot {
        max-width: 35%;
    }
}

img {
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none;
}

.text {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    font-family: var(--light-font);
}

.centerContainer {
    display: grid;
    vertical-align: middle;
    padding-bottom: 4em;
}

.click {
    color: inherit;
    text-decoration: none;
}

.click:hover {
    cursor: pointer;
}

.dark {
    border: 2px solid var(--minorwhite);
}

.light {
    border: 2px solid var(--charcoal);
}

@media (min-width:320px)  {
    .container {
        grid-template-columns: 15% 70% 15%;
        margin-top: 10%;
    }

    .text {
        font-size: 15px;
    }
}

@media (min-width:720px)  {
    .container {
        grid-template-columns: 20% 60% 20%;
        margin-top: 10%;
    }

    .text {
        font-size: 15px;
    }
}

@media (min-width:1025px)  {
    .container {
        grid-template-columns: 30% 40% 30%;
        margin-top: 8.5%;
    }

    .text {
        font-size: 16px;
    }
}

@media (min-width:1281px) {
    .container {
        grid-template-columns: 30% 40% 30%;
        margin-top: 6%;
    }

    .text {
        font-size: 17px;
    }
}
